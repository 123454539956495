/*
 * @Author: Trevor
 * @Github: https://github.com/TrevorYS
 * @Date: 2021-03-04 09:20:58
 * @LastEditors: Trevor
 * @LastEditTime: 2021-04-26 15:21:55
 */
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  { path: "/", redirect: "/index" },
  {
    path: "/index",
    name: "Home",
    component: () => import("@/views/index/index.vue"),
  },
  {
    path: "/path",
    name: "Path",
    component: () => import("@/views/path/index.vue"),
  },
  {
    path: "/guide",
    name: "guide",
    component: () => import("@/views/guide/index.vue"),
  },
  {
    path: "/search",
    name: "search",
    component: () => import("@/views/search/index.vue"),
  },
  {
    path: "/cardPoint",
    name: "cardPoint",
    component: () => import("@/views/cardPoint/index.vue"),
  },
  {
    path: "/t",
    name: "t",
    component: () => import("@/views/t/index.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
export default router;
